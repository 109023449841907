import React from 'react'
import { Link } from 'gatsby'
//import github from '../img/github-icon.svg'
import logo from '../img/ef-logo-tm3.svg'

import facebook from '../img/social/facebook.svg'
import instagram from '../img/social/instagram.svg'
import twitter from '../img/social/twitter.svg'


/*
function openNetlifyModal() {
  const netlifyIdentity = window.netlifyIdentity;
  //const user;
  if(netlifyIdentity){

    //const user = netlifyIdentity.currentUser();
    //alert(user.email)
    //alert(user.user_metadata.full_name)
    //console.log(user);
    //netlifyIdentity.logout();
    netlifyIdentity.open();
      //  alert("open identity modal 4");

  }
  else{
    console.log('netlifyIdentity not defined')
  }
}
*/

const Navbar = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      navBarActiveClass: '',
    }
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: 'is-active',
            })
          : this.setState({
              navBarActiveClass: '',
            })
      }
    )
  }

  render() {
    return (
      <nav
        className="navbar is-transparent"
        role="navigation"
        aria-label="main-navigation"
      >
        <div name="top" className="container">


          <div className="navbar-brand">
            <Link to="/" className="navbar-itemx" title="Logo">
              <img className="ef-navbar-logo" src={logo} alt="Effective Foods" />
            </Link>
            {/* Hamburger menu */}
            <div
              className={`navbar-burger burger ${this.state.navBarActiveClass}`}
              data-target="navMenu"
              onClick={() => this.toggleHamburger()}
            >
              <span />
              <span />
              <span />
            </div>
          </div>
          <div
            id="navMenu"
            className={`navbar-menu ${this.state.navBarActiveClass}`}
          >
            <div className="navbar-start has-text-centered">
              <Link className="navbar-item" activeStyle={{fontWeight: "bold", color: "#00693eff"}} partiallyActive={true} to="/immunity">
                Immunity Theory
              </Link>
              <Link className="navbar-item" activeStyle={{fontWeight: "bold", color: "#00693eff"}} partiallyActive={true}  to="/meal-plans/immunity-meal-plan">
                Immunity Meal Plan & Smoothie
              </Link>
              <Link className="navbar-item" activeStyle={{fontWeight: "bold", color: "#00693eff"}} partiallyActive={true}  to="/meal-plans/antiviral-foods-drinks">
                Antiviral Foods
              </Link>
              <Link className="navbar-item" activeStyle={{fontWeight: "bold", color: "#00693eff"}} partiallyActive={true}  to="/restaurants">
                Restaurants
              </Link>
              <Link className="navbar-item" activeStyle={{fontWeight: "bold", color: "#00693eff"}} partiallyActive={true}  to="/products">
                Products
              </Link>
              <Link className="navbar-item" activeStyle={{fontWeight: "bold", color: "#00693eff"}} partiallyActive={true}  to="/partners/food-service">
                Food Service
              </Link>
              <Link className="navbar-item" activeStyle={{fontWeight: "bold", color: "#00693eff"}} partiallyActive={true}  to="/partners/wellness-program">
                Wellness Program
              </Link>
                {/*
              <Link className="navbar-item" to="/about">
                About
              </Link>

              <Link className="navbar-item" to="/blog">
                Blog
              </Link>
              */}


            </div>
            <div className="navbar-end has-text-centered">







              <div className="column is-3x" style={{margin : "0px", backgroundColorx: "#ff0000"}}>
                              <div className="content has-text-centered ef-social-top-sectionx">

                              <div className="column is-4x social ef-social-top-sectionx has-text-centered">
                                <a title="facebook" href="https://www.facebook.com/EffectiveFoods" style={{backgroundColor: '#00693eff'}}>
                                  <img
                                    src={facebook}
                                    alt="Facebook"
                                    style={{ width: '1em', height: '1em', fillx: 'white', backgroundColorx: 'white', filter: 'invert(1)'}}
                                  />
                                </a>
                                <a title="twitter" href="https://twitter.com/EffectiveFoods" style={{backgroundColor: '#00693eff'}}>
                                  <img
                                    className="fas fa-lg"
                                    src={twitter}
                                    alt="Twitter"
                                    style={{ width: '1em', height: '1em', filter: 'invert(1)' }}
                                  />
                                </a>
                                <a title="instagram" href="https://www.instagram.com/effectivefoods" style={{backgroundColor: '#00693eff'}}>
                                  <img
                                    src={instagram}
                                    alt="Instagram"
                                    style={{ width: '1em', height: '1em', filter: 'invert(1)' }}
                                  />
                                </a>
                              </div>
                              </div>



            </div>




            </div>
          </div>
        </div>
      </nav>
    )
  }
}

export default Navbar
