//import React, { Component } from 'react'
import React from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import './all.sass'
//import useSiteMetadata from './SiteMetadata'
import { withPrefix } from 'gatsby'
//import SEO from '../components/Seo';

const pageMetadataDefault  = {
  title: 'Title',
  description: 'Description'
}


/*
function initNetlifyIdentity() {
  console.log("initNetlifyIdentity called.")
  const script = document.createElement("script");

  script.src = "https://identity.netlify.com/v1/netlify-identity-widget.js"
  script.async = true;

  document.body.appendChild(script);
}
*/

/*
function openNetlifyModal() {
  const netlifyIdentity = window.netlifyIdentity;
  if(netlifyIdentity){
    netlifyIdentity.open();
    alert("open modal");
  }
  else{
    console.log('netlifyIdentity not defined')
  }
}
*/

/*
var user = null;
function getUser() {


  if (typeof window !== 'undefined') {

    const netlifyIdentity = window.netlifyIdentity;
    //const user;
    if(netlifyIdentity){
      user = netlifyIdentity.currentUser();
      //alert("app: " + user.email)
      alert(user.user_metadata.full_name)
      //const userName = user.user_metadata.full_name;
      return user;
    //console.log(user);
    //netlifyIdentity.open();
    }
    else{
      console.log('netlifyIdentity not defined')
      //alert("no window");
    }
  }
}

*/
/*
class NetlifyIdentity extends Component {
  componentDidMount() {
    initNetlifyIdentity();
  }
  render() {
    return(<div></div>)
  }
}
*/
const TemplateWrapper = ({ children, pageMetadata = pageMetadataDefault}) => {
  //const { title, description} = useSiteMetadata()


  return (
    <div id="top">
      <Helmet>
        <html lang="en" />
        <title>{pageMetadata.title}</title>
        <meta name="description" content={pageMetadata.description} />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix('/')}img/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-16x16.png`}
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href={`${withPrefix('/')}img/safari-pinned-tab.svg`}
          color="#ff4400"
        />

        <link rel="shortcut icon" type="image/png" href={'${withPrefix('/')}img/apple-touch-icon.png'}/>
        <link rel="apple-touch-icon" href={'${withPrefix('/')}img/apple-touch-icon.png'}/>
        <link rel="apple-touch-icon" sizes="76x76" href={'${withPrefix('/')}img/apple-touch-icon.png'}/>
        <link rel="apple-touch-icon" sizes="120x120" href={'${withPrefix('/')}img/apple-touch-icon.png'}/>
        <link rel="apple-touch-icon" sizes="152x152" href={'${withPrefix('/')}img/apple-touch-icon.png'}/>


        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={pageMetadata.title} />
        <meta property="og:url" content="/" />
        <meta
          property="og:image"
          content={`${withPrefix('/')}img/og-image.jpg`}
        />
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-165386336-1"></script>
        <script src={withPrefix('gtag.js')} type="text/javascript" />

      </Helmet>
      <Navbar />
      <div>{children}</div>
      <Footer />



    </div>




  )
}

export default TemplateWrapper
