import React from 'react'
import { Link } from 'gatsby'

import logo from '../img/ef-logo-tm3.svg'
import facebook from '../img/social/facebook.svg'
import instagram from '../img/social/instagram.svg'
import twitter from '../img/social/twitter.svg'
//import vimeo from '../img/social/vimeo.svg'

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer has-background-blackx has-text-white-terx" style={{marginTop: "0px", backgroundColor: "#161D2B"}}>
        <div className="content has-text-centered has-background-blackx has-text-white-ter" style={{backgroundColor: "#161D2B"}}>
        <div className="content has-text-centered" style={{height:"100px", marginTop: "-50px", padding: "0px"}}>
          <img
            src={logo}
            alt="Effective Foods"
            style={{ width: '14em', height: '10em', fill:'white', backgroundColorx: 'white', filter: 'brightness(0) invert(1)' }}
          />
        </div>

          <div className="container has-background-blackx has-text-white-ter"  style={{backgroundColor: "#161D2B"}}>

          <div className="content" style={{textAlign:'left', padding:'20px', fontSize:'16px'}}><h4 style={{color:'white'}}>FDA Disclaimer & Warning</h4>
          These statements made on this website have not been evaluated by the Food and Drug Administration. This website and its products are not intended to diagnose, treat, cure, or prevent any disease. Please consult your physician before implementing any new diet, especially if you have pre-existing medical conditions, taking prescribed medications, pregnant, or nursing. Avoid all foods that you are allergic to. Discontinue using the meal plan and consult your physician if any adverse reactions occur. Not intended for use by persons under the age of 18. The statements made on this website are for educational purposes only and are not meant to replace the advice of your physician or healthcare provider.


          </div>

            <div className="columns">
              <div className="column is-4">
                <section className="menu">
                  <ul className="menu-list">

                    <li>
                    <Link className="navbar-item" to="/immunity#top">
                      Whole Foods Immunity Theory
                    </Link>
                    </li>
                    <li>
                    <Link className="navbar-item" to="/meal-plans/immunity-meal-plan#top">
                      Immunity Meal Plan & Smoothie
                    </Link>
                    </li>
                    <li>
                    <Link className="navbar-item" to="/meal-plans/antiviral-foods-drinks#top">
                      Antiviral Foods & Drinks
                    </Link>
                    </li>
                    <li>
                    <Link className="navbar-item" to="/restaurants#top">
                      Find Restaurants
                    </Link>
                    </li>
                    <li>
                    <Link className="navbar-item" to="/products#top">
                      Find Products
                    </Link>
                    </li>

                  </ul>
                </section>
              </div>
              <div className="column is-4">
                <section>
                  <ul className="menu-list">
                  <li>
                  <Link className="navbar-item" to="/partners/food-service#top">
                    Food Service Programs
                  </Link>
                  <Link className="navbar-item" to="/partners/wellness-program#top">
                    Wellness Program
                  </Link>
                  </li>
                  <li>
                    <Link className="navbar-item" to="/about#top">
                      About
                    </Link>
                  </li>
                    <li>

                    <Link className="navbar-item" to="# ">
                      Contact Us:<br></br>info@effectivefoods.com
                    </Link>

                    </li>
                  </ul>
                </section>
              </div>
              <div className="column is-4 social ef-social-footer">
                <a title="facebook" href="https://www.facebook.com/EffectiveFoods">
                  <img
                    src={facebook}
                    alt="Facebook"
                    style={{ width: '1em', height: '1em' }}
                  />
                </a>
                <a title="twitter" href="https://twitter.com/EffectiveFoods">
                  <img
                    className="fas fa-lg"
                    src={twitter}
                    alt="Twitter"
                    style={{ width: '1em', height: '1em' }}
                  />
                </a>
                <a title="instagram" href="https://www.instagram.com/effectivefoods">
                  <img
                    src={instagram}
                    alt="Instagram"
                    style={{ width: '1em', height: '1em' }}
                  />
                </a>
              </div>
            </div><p style={{fontSize:"12px"}}>&copy; 2020 Effective Foods. All rights reserved.</p>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
